//compass imports
@import "compass";

//partials
@import "bower_components/bootstrap/dist/css/bootstrap.min.css";
@import "bower_components/animate.css/animate.min.css";
@import "bower_components/lightbox2/dist/css/lightbox.min.css";

$fa-font-path: "../../bower_components/font-awesome/fonts";
@import "../../bower_components/font-awesome/scss/font-awesome";
@import "../../bower_components/slick-carousel/slick/slick.scss";

//base style
@import "base/_mixin";
@import "base/_fonts";
@import "base/_variables";
@import "base/_extend";
@import "base/_reset";

//style
@import "_btnsIcons";
@import "_header";
@import "_fullScreen";
@import "_menus";
@import "_about";
@import "_projects";
@import "_materials";
@import "_productsAndOrders";
@import "_followUs";
@import "_footer";
@import "_typography";
@import "_main";