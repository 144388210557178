/*reset css*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing:border-box;
}

html {
  line-height: 1;
}

body {
  line-height: 1;
  font-size: 14px;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;

  &:before,
  &:after {
    content: "";
    content: none;
  }
}

img {
  max-width: 100%;
  height: auto;
  border: none;
  outline: none;
}

a {
  text-decoration: none;
  outline: none;
  &:hover {
    text-decoration: none;
  }
  &:active {
    outline: none;
    text-decoration: none;
  }
  &:visited {
    outline: none;
    text-decoration: none;
  }
  &:focus {
    outline: none;
    text-decoration: none;
  }
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
  box-sizing:border-box;
}

:focus {
  outline: none;
}

h1,h2,h3,h4,h5,h6{
  font-weight:normal;
}

button {
  display: inline-block;
  border: none;
  vertical-align: middle;
  background: transparent;
  padding: 0;
  outline: none;
  cursor: pointer;
  &:visited {
    outline: none;
  }
  &:focus {
    outline: none;
  }
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
}

.cf:before, .cf:after {
  content:'.';
  display:block;
  overflow:hidden;
  visibility:hidden;
  font-size:0;
  line-height:0;
  width:0;
  height:0;
}

.cf:after{
  clear:both;
}