.materials-slider-wrap {
  width: 100%;
  background-color: $mint;
  padding: 30px;
  overflow: hidden;
  @include r(1000) {
    padding: 0;
  }
  .materials-slider-inner {
    width: 100%;
    min-height: 540px;
    background-color: #ffffff;
    padding: 46px 0 60px 90px;
    @include r(1200) {
      padding: 46px 0 60px 30px;
    }
    @include r(1000) {
      background-color: $light;
      min-height: 100%;
    }
    @include r(720) {
      width: 100%;
      padding: 30px 0;
    }
  }
  .main-title {
    margin-bottom: 10px;
    @include r(720) {
      text-align: center;
    }
  }
  .materials-slider {
    .slick-dots {
      padding: 20px 0;
      margin: 0;
      position: absolute;
      left: 0;
      bottom: -20px;
      background-color: #ffffff;
      z-index: 30;
      &:before {
        @extend %before;
        width: 120%;
        height: 1px;
        background-color: rgba($grey, 0.2);
        @extend %vam;
        left: -10%;
      }
      li {
        @extend %ibm;
        margin-right: 10px;
        &:last-of-type {
          margin-right: 0;
        }
        & button {
          width: 20px;
          height: 20px;
          background-color: #ffffff;
          border: solid 1px rgba($grey, 0.4);
          text-indent: -9999px;
          position: relative;
          border-radius: 50%;
          transition: all $time;
          &:before {
            @extend %before;
            width: 0;
            height: 0;
            background-color: rgba($grey, 0.4);
            @extend %cam;
            border-radius: 50%;
            transition: all $time;
          }
          &:hover {
            border-color: rgba($grey, 0.7);
            &:before {
              background-color: rgba($grey, 0.7);
              width: 4px;
              height: 4px;
            }
          }
        }
        &.slick-active {
          & button {
            width: 40px;
            height: 40px;
            border-color: $orange;
            &:before {
              background-color: $orange;
              width: 6px;
              height: 6px;
            }
          }
        }
      }
      @include r(1000) {
        background-color: $light;
        & li {
          button {
            background-color: $light;
          }
        }
      }
      @include r(870) {
        bottom: -60px;
      }
      @include r(720) {
        width: 100%;
        height: 40px;
        position: relative;
        text-align: center;
        bottom: 0;
        padding: 0;
        margin-top: 40px;
        &:before {
          width: 80%;
          left: 50%;
          @include transform(translateX(-50%));
        }
      }
    }
  }
  .mat-slide-item {
    width: 100%;
    @include display-flex();
    @include flex-wrap(wrap);
    @include justify-content(left);
    .mat-slide-item-img-wrap {
      width: 45%;
      @include r(720) {
        width: 100%;
      }
      .mat-slide-item-img-back {
        width: 330px;
        height: 270px;
        margin: 35px 0 0 120px;
        background: {
          image: url("../img/elements_material_slider_330_270.png");
          repeat: no-repeat;
          position: center center;
        };
        position: relative;
        z-index: 20;
        @include r(1200) {
          margin: 35px 0 0 0;
        }
        @include r(870) {
          width: 270px;
        }
        @include r(720) {
          width: 310px;
          margin: 30px auto 10px;
        }
        .mat-slide-item-img-bg {
          width: 330px;
          height: 270px;
          background: {
            image: url("../img/elements_material_slider_triangle_330_270.png");
            repeat: no-repeat;
            position: center center;
          };
          position: relative;
          z-index: 30;
          @include r(870) {
            width: 270px;
            background-position: -60px center;
          }
          @include r(720) {
            width: 310px;
            background-position: -20px center;
          }
        }
        .mat-slide-item-img {
          display: block;
          width: 100px;
          height: 100px;
          border: solid 6px #ffffff;
          background-color: #ffffff;
          position: absolute;
          left: 133px;
          top: 67px;
          z-index: 40;
          overflow: hidden;
          border-radius: 50%;
          box-shadow: 4px 4px 24px 0 rgba($black,0.22);
          transition: all $time;
          @include r(1000) {
            border-color: $light;
          }
          @include r(870) {
            left: 73px;
          }
          @include r(720) {
            left: 113px;
          }
          & img {
            opacity: 0.3;
            border-radius: 50%;
            transition: all $time;
          }
          &:hover {
            & img {
              opacity: 0;
              @include transform(scale(1.1));
            }
          }
        }
      }
    }
    .mat-slide-item-desc {
      width: 55%;
      padding-right: 120px;
      @include r(1200) {
        padding-right: 30px;
      }
      @include r(720) {
        width: 100%;
        padding: 0 30px;
        text-align: center;
      }
      .mat-slide-item-desc-title {
        font-size: 1.563em;
        color: $orange;
        text-transform: uppercase;
        line-height: 1.3;
        @include r(720) {
          margin-bottom: 18px;
        }
      }
      .mat-slide-item-desc-sub-title {
        font-size: 1.125em;
        color: $black;
        text-transform: uppercase;
        line-height: 1.3;
        margin: 18px 0;
        @include r(720) {
          display: none;
        }
      }
      .mat-slide-item-desc-item {
        color: $black;
        line-height: 1.2;
      }
      .mat-slide-item-desc-inner {
        height: 254px;
        overflow: hidden;
        @include r(1000) {
          height: auto;
          overflow: visible;
        }
      }
      .cta-btn {
        font: {
          family: $fontAltMd;
          size: 0.813em;
        };
        min-width: 150px;
        line-height: 38px;
        @include r(870) {
          margin-top: 30px;
        }
      }
    }
  }
}

//partners list
.partners-list-wrap {
  ul {
    width: 100%;
    margin: 0;
    padding: 20px 0 30px 0;
    list-style: none;
    font-size: 0;
    @include display-flex();
    @include flex-wrap(wrap);
    @include justify-content(flex-start);
    @include r(1200) {
      padding: 20px 0;
    }
    @include r(870) {
      display: none;
    }
    li {
      @extend %ibm;
      font-size: 0.875rem;
      margin: 0 10px 5px 0;
      position: relative;
      color: $grey;
      @include r(1000) {
        width: calc(50% - 10px);
      }
      @include r(620) {
        width: 100%;
        margin: 0 0 10px 0;
      }
      &.title {
        margin: 0 0 10px 0;
        width: 100%;
        font-size: 1.125rem;
        text-transform: uppercase;
      }
      a {
        color: $grey;
        transition: all $time;
        &:hover {
          color: $orange;
        }
      }
    }
  }
}

.material-index-layout {

}

.materials-list {
  @include display-flex();
  @include flex-wrap(wrap);
  @include align-items(top);
  @include justify-content(space-between);
  margin-top: 30px;
  .materials-list-item {
    width: calc(50% - 20px);
    margin-bottom: 40px;
    min-height: 400px;
    border: solid 1px rgba($lightGrey, 0.6);
    position: relative;
    padding: 30px;
    background: {
      image: url("../img/elements_material_slider_330_270.png");
      repeat: no-repeat;
      position: 70px 75px;
    };
    z-index: 20;
    transition: all $time;
    cursor: pointer;
    @include r(1000) {
      width: calc(50% - 15px);
      margin-bottom: 30px;
      background-position: 50px 75px;
    }
    @include r(870) {
      width: 100%;
      background-position: center 75px;
    }
    @include r(510) {
      background: none;
      padding: 20px;
    }
    .materials-list-item-link {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 40;
    }
    .materials-list-item-title {
      font-size: 1.563em;
      color: $orange;
      text-transform: uppercase;
      line-height: 1.3;
    }
    .mat-slide-item-img-bg {
      width: 330px;
      height: 270px;
      margin: 12px auto;
      background: {
        image: url("../img/elements_material_slider_triangle_330_270.png");
        repeat: no-repeat;
        position: center center;
      };
      position: relative;
      z-index: 30;
      transition: all $time;
      @include r(870) {
        width: 270px;
        background-position: -60px center;
      }
      @include r(510) {
        width: 240px;
        height: 240px;
        margin: 24px 0 12px -17px;
        background-position: -60px 0;
      }
    }
    .mat-slide-item-img {
      display: block;
      width: 100px;
      height: 100px;
      border: solid 6px #ffffff;
      background-color: #ffffff;
      position: absolute;
      top: 67px;
      left: 133px;
      z-index: 40;
      overflow: hidden;
      border-radius: 50%;
      box-shadow: 4px 4px 24px 0 rgba($black,0.22);
      transition: all $time;
      @include r(870) {
        left: 73px;
      }
      @include r(510) {
        top: 57px;
      }
      & img {
        opacity: 0.3;
        border-radius: 50%;
        transition: all $time;
      }
    }
    .materials-list-item-desc {
      line-height: 1.4;
      color: rgba($black, 0.8);
      transition: all $time;
    }
    &:hover {
      border-color: $orange;
      .mat-slide-item-img-bg {
        opacity: 0.75;
      }
      .mat-slide-item-img {
        & img {
          opacity: 1;
          @include transform(scale(1.1));
        }
      }
      .materials-list-item-desc {
        color: rgba($black, 1);
      }
    }
  }
}

//single material
.material-single-layout {
  .layout-content-wrap {
    .project-single-content-desc {
      margin: 20px 0;
    }
  }
}

.insert-examples-wrap {
  width: 100%;
  margin-bottom: 60px;
  position: relative;
  .insert-examples-item {
    width: 100%;
    height: 500px;
    background: {
      repeat: no-repeat;
      position: center center;
      size: cover;
    };
    @include r(1000) {
      height: 400px;
    }
    @include r(770) {
      height: 200px;
    }
  }
  .slick-dots {
    width: 100%;
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 20;
    @include r(1000) {
      bottom: 20px;
      left: 20px;
    }
    @include r(770) {
      position: relative;
      text-align: center;
      left: auto;
      bottom: auto;
      margin: 10px 0;
    }
    li {
      @extend %ibm;
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
      & button {
        width: 12px;
        height: 12px;
        border: solid 2px #ffffff;
        text-indent: -9999px;
        position: relative;
        border-radius: 50%;
        transition: all $time;
        @include r(770) {
          border-color: $lightGrey;
        }
        &:hover {
          background-color: #ffffff;
          @include r(770) {
            background-color: $lightGrey;
          }
        }
      }
      &.slick-active {
        & button {
          background-color: $orange;
          border-color: $orange;
        }
      }
    }
  }
}

.brand-material-single-layout {
  .with-sidebar-content-part {
    .project-single-content-desc-add {
      margin-top: 50px;
      p {
        color: $grey;
      }
    }
  }
  .brands-material-examples {
    .main-title-wrap {
      margin-bottom: 30px;
    }
    .brands-material-ex-group {
      margin-bottom: 40px;
      &:last-of-type {
        margin-bottom: 0;
      }
      .brands-material-ex-group-title {
        text-transform: uppercase;
        margin-bottom: 30px;
        line-height: 1.3;
      }
      .brands-material-ex-group-list {
        @include display-flex();
        @include flex-wrap(wrap);
        @include align-items(top);
        @include justify-content(left);
        .brands-material-ex-group-item {
          display: block;
          width: calc(20% - 10px);
          min-height: 200px;
          padding: 10px;
          margin: 0 10px 10px 0;
          border: solid 1px rgba($lightGrey, 0.6);
          transition: all $time;
          @include r(1200) {
            width: calc(25% - 10px);
          }
          @include r(1100) {
            width: calc(33.3% - 10px);
          }
          @include r(870) {
            width: calc(20% - 10px);
          }
          @include r(770) {
            width: calc(25% - 10px);
          }
          @include r(670) {
            width: calc(33.3% - 10px);
          }
          @include r(510) {
            width: calc(50% - 10px);
          }
          @include r(400) {
            width: 100%;
            margin: 0 0 10px 0;
          }
          .brands-material-ex-group-item-pic {
            display: block;
            width: 100%;
            height: 130px;
            background: {
              repeat: no-repeat;
              position: center;
              size: cover;
            };
          }
          .brands-material-ex-group-item-text {
            display: block;
            margin-top: 20px;
            text-align: center;
            color: $grey;
            transition: color $time;
          }
          &:hover {
            border-color: $orange;
            .brands-material-ex-group-item-text {
              color: $black;
            }
          }
        }
      }
    }
  }
  .brands-material-sidebar {
    width: 100%;
    padding: 30px;
    border: solid 1px rgba($lightGrey, 0.6);
    .sidebar-title {
      text-transform: uppercase;
      margin-bottom: 25px;
    }
    .material-group {
      margin-bottom: 30px;
      &:last-of-type {
        margin-bottom: 0;
      }
      li {
        margin-bottom: 15px;
        &:last-of-type {
          margin-bottom: 0;
        }
        &.material-group-title {
          font-size: 1.563em;
          color: $orange;
          text-transform: uppercase;
          line-height: 1.3;
          margin-bottom: 15px;
        }
        a {
          color: $black;
          white-space: nowrap;
          line-height: 1.3;
          & span {
            color: inherit;
            font-size: 1.125em;
            position: relative;
            transition: color $time;
            &:before {
              @extend %before;
              width: 100%;
              height: 1px;
              border-bottom: solid 1px rgba($black, 0.6);
              left: 0;
              bottom: 0;
              transition: all $time;
            }
          }
          & sup {
            font-size: 0.875em;
            position: relative;
            top: 0;
          }
          &:hover {
            span {
              color: $orange;
              &:before {
                border-bottom-color: $orange;
                width: 0;
              }
            }
            & sup {
              color: $orange;
            }
          }
        }
        &.active {
          a {
            padding-left: 20px;
            position: relative;
            &:before {
              @extend %before;
              width: 10px;
              height: 1px;
              background-color: $orange;
              position: absolute;
              @extend %vam;
              left: 0;
              transition: all $time;
            }
            span {
              color: $orange;
              &:before {
                border-bottom-color: $orange;
                width: 0;
              }
            }
            & sup {
              color: $orange;
            }
          }
        }
      }
    }
  }
}

