/*extends*/

%ibt {
  display: inline-block;
  vertical-align: top;
}

%ibm {
  display: inline-block;
  vertical-align: middle;
}

%ibb {
  display: inline-block;
  vertical-align: bottom;
}

%before {
  content: '';
  display: block;
  position: absolute;
}

%before-block {
  content: '';
  display: block;
}

%table {
  display: table;
  font-size: 0 !important;
  width: 100%;
}

//centering
%vam {
  top: 50%;
  @include transform(translateY(-50%));
}

%ham {
  left: 50%;
  @include transform(translateX(-50%));
}
%cam {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  @include transform(translate(-50%, -50%));
}

