.about-module {
  padding: 220px 0 200px 0;
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
  @include r(870) {
    padding: 90px 0 90px 0;
  }
  @include r(620) {
    padding: 60px 0;
  }
  .about-decor-overlay {
    width: 100%;
    height: 100%;
    background: {
      image: url("../img/elements_about_1280_480.png");
      repeat: no-repeat;
      position: center 108px;
    };
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .content-wrap {
    max-width: 680px;
    margin-left: $leftOffset;
    position: relative;
    @include r(1200) {
      margin-left: $leftOffsetMiddle;
    }
    @include r(1000) {
      max-width: 100%;
      margin-left: $leftOffsetSmall;
      padding-right: 30px;
    }
    @include r(720) {
      margin-left: 0;
      padding-left: 30px;
    }
    z-index: 20;
    .decor-title {
      color: $orange;
      margin-bottom: 27px;
      &:before {
        background-color: $orange;
      }
    }
    .content-item {
      font: {
        size: 1.563em;
      };
      color: $altBlack;
      line-height: 1.2;
      @include r(870) {
        font: {
          size: 1.438em;
        };
      }
      @include r(620) {
        font: {
          size: 1.250em;
        };
      }
    }
  }
  .about-decor-letter {
    position: absolute;
    @include r(870) {
      display: none;
    }
    &.top-letter {
      width: 90px;
      height: 92px;
      top: 100px;
      left: 120px;
      @include r(1200) {
        left: 60px;
      }
      @include r(1000) {
        left: 30px;
      }
    }
    &.bottom-letter {
      width: 86px;
      height: 100px;
      bottom: 70px;
      right: 120px;
      @include r(1200) {
        right: 50px;
      }
      @include r(1000) {
        right: 30px;
      }
    }
  }
}

//contacts
.map-wrap {
  width: 100%;
  height: 540px;
  background-color: rgba($lightGrey,0.4);
}
.contact-form-wrap {
  .contact-form-title {
    text-transform: uppercase;
    color: $orange;
    font: {
      size: 1.125em;
    };
    line-height: 1.4;
    margin-bottom: 30px;
  }
  .contact-form-group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
    &:last-of-type {
      margin-bottom: 0;
    }
    .contact-form-item {
      width: calc(50% - 20px);
      @include r (1000) {
        width: 100%;
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .contact-form-label {
        display: block;
        font: {
          family: $fontAlt;
          size: 0.875em;
        };
        text-transform: uppercase;
        line-height: 1.3;
        margin-bottom: 5px;
        & i {
          color: $orange;
          position: relative;
          top: -1px;
          margin-left: 2px;
        }
      }
      .contact-form-field {
        .form-field {
          width: 100%;
          padding: 0 15px;
          height: 40px;
          font: {
            family: $font;
            size: 0.875em;
          };
          border: solid 1px rgba($lightGrey, 0.6);
          transition: all $time;
          &.text-area-field {
            padding: 15px;
            min-height: 160px;
            resize: vertical;
          }
          &:focus {
            border-color: $orange;
          }
        }
      }
      .form-legend {
        display: block;
        margin-top: 10px;
        font-size: 0.938em;
        color: $lightGrey;
        & i {
          color: $orange;
          position: relative;
          top: -1px;
          margin-left: 2px;
        }
      }
    }
    &.single-row {
      .contact-form-item {
        width: 100%;
      }
    }
  }
  .contact-form-message {
    width: 100%;
    padding: 15px;
    border: solid 1px transparent;
    font: {
      family: $fontAlt;
      size: 0.875em;
    };
    line-height: 1.3;
    margin-bottom: 15px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &.error-state {
      border-color: $error;
      color: $error;
    }
    &.success-state {
      border-color: $success;
      color: $success;
    }
  }
}
.contacts-sidebar {
  width: 100%;
  padding: 30px;
  border: solid 1px rgba($lightGrey, 0.6);
  .contacts-sidebar-logo {
    width: 100%;
    margin-bottom: 30px;
    .footer-contacts-logo {
      font-size: 0;
      @include r(620) {
        margin: 0 auto;
      }
      .logo-icon {
        @extend %ibm;
        width: 50px;
        height: 50px;
        transition: all $time;
        @include r(510) {
          margin-right: 0;
        }
      }
      .logo-text {
        display: block;
        width: 138px;
        height: 50px;
        margin-top: 20px;
        position: relative;
        left: 0;
        top: 0;
        transition: top $time;
        @include r(510) {
          display: none;
        }
      }
    }
  }
}

.contacts-sidebar-address-row {
  margin-bottom: 25px;
  line-height: 1.4;
  color: $grey;
  font: {
    family: $fontAlt;
  };
  a {
    color: $grey;
    transition: all $time;
    &:hover {
      color: $orange;
    }
  }
  .contacts-sidebar-address-label {
    margin-bottom: 3px;
    color: $black;
    font: {
      family: $fontAltMd;
      size: 0.875em;
    };
    text-transform: uppercase;
  }
  .social-links {
    width: 100%;
    text-align: left;
    margin-top: 10px;
    a {
      border-color: rgba($grey,0.3);
      &:before {
        color: $orange;
      }
      &:hover {
        border-color: $orange;
        background-color: $orange;
        &:before {
          color: #ffffff;
        }
      }
    }
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}

.map-window {
  .contacts-sidebar-address-row {
    margin-bottom: 15px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}