//settings
$time: 0.4s;
$leftOffset: 355px;
$leftOffsetMiddle: 200px;
$leftOffsetSmall: 110px;

//fonts
$font: 'DINRoundPro', Arial, Helvetica, sans-serif;
$fontMd: 'DINRoundPro-Medi', Arial, Helvetica, sans-serif;
$fontAlt: 'Geometria', Arial, Helvetica, sans-serif;
$fontAltMd: 'Geometria-Medium', Arial, Helvetica, sans-serif;
$fontAltB: 'Geometria-Bold', Arial, Helvetica, sans-serif;

//colors
$orange: #ff5d47;
$mint: #cfe4e1;
$black: #191a1f;
$altBlack: #282b34;
$deepBlue: #201d29;
$grey: #93969c;
$lightGrey: #cacacc;
$light: #f6f6f7;

$error: #cc6666;
$success: #39b54a;

//social networks color templates
$fb-color: #3b5998;  /*facebook*/
$tw-color: #5ea9dd;  /*twitter*/
$yt-color: #c84141;  /*youtube*/
$gpl-color: #f22c24; /*google plus*/
$vk-color: #4c75a3;  /*vkontakte*/
$in-color: #0085af;  /*linkedIn*/
$lj-color: #15374c;  /*jive journal*/
$inst-color: #125688;  /*instagram*/
$pin-color: #bd081c;  /*pinterest*/