/* fonts list
 * ex. @include font-face(ProximaNova-Black,ProximaNova-Black);
 */
@include font-face(DINRoundPro,DINRoundPro);
@include font-face(DINRoundPro-Medi,DINRoundPro-Medi);

@include font-face(Geometria,Geometria);
@include font-face(Geometria-Medium,Geometria-Medium);
@include font-face(Geometria-Bold,Geometria-Bold);

@include font-face(icomoon,icomoon);
