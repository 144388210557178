/*buttons, icons, sprites*/

.btn {
  padding: 0;
  border: none;
  font-weight: normal;
  text-decoration: none;
  border-radius: 0;
  box-shadow: none;
  transition: all $time;
  &:active {
    box-shadow: none;
    outline: none;
  }
}

.cta-btn {
  @extend %ibm;
  min-width: 260px;
  padding: 0 10px;
  line-height: 48px;
  background-color: transparent;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  &.line-cta {
    color: $orange;
    border: solid 1px $orange;
    &:after {
      border-color: $orange;
    }
    &:hover {
      color: #ffffff;
      background-color: $orange;
    }
  }
  &.fill-cta {
    color: #ffffff;
    background-color: $orange;
    border: solid 1px transparent;
    &:hover {
      color: $orange;
      background-color: transparent;
      border: solid 1px $orange;
    }
  }
}

.contact-us-cta {
  padding: 0 40px 0 50px;
  line-height: 38px;
  border: solid 1px #ffffff;
  font: {
    family: $fontAltMd;
    size: 0.813em;
  };
  color: #ffffff;
  text-transform: uppercase;
  position: absolute;
  right: 30px;
  bottom: 60px;
  @include r(510) {
    width: 38px;
    padding: 0;
    color: transparent;
    bottom: 30px;
  }
  &:before {
    @include icomoon;
    content: "\e902";
    font-size: 22px;
    position: absolute;
    @extend %vam;
    left: 14px;
    transition: all $time;
    @include r(510) {
      left: 7px;
      color: #ffffff;
    }
  }
  &:hover {
    background-color: $orange;
    border-color: $orange;
    color: #ffffff;
    &:before {
      left: 20px;
    }
    @include r(510) {
      color: transparent;
      &:before {
        left: 7px;
      }
    }
  }
}

.social-links {
  font-size: 0;
  a {
    @extend %ibm;
    width: 40px;
    height: 40px;
    border: solid 1px transparent;
    text-align: center;
    margin-right: 10px;
    transition: all $time;
    &:before {
      @include fontIconAw;
      line-height: 40px;
      font-size: 13px;
      color: inherit;
      transition: all $time;
    }
    &:last-of-type {
      margin-right: 0;
    }
    &:hover {
      &:before {
        color: #ffffff;
      }
    }
    &.tw {
      &:before {
        content: "\f099";
      }
      &:hover {
        border-color: $tw-color;
        background-color: $tw-color;
      }
    }
    &.fb {
      &:before {
        content: "\f09a";
      }
      &:hover {
        border-color: $fb-color;
        background-color: $fb-color;
      }
    }
    &.ln {
      &:before {
        content: "\f0e1";
      }
      &:hover {
        border-color: $in-color;
        background-color: $in-color;
      }
    }
    &.gpl  {
      &:before {
        content: "\f0d5";
      }
      &:hover {
        border-color: $gpl-color;
        background-color: $gpl-color;
      }
    }
    &.inst {
      &:before {
        content: "\f16d";
        font-size: 14px;
      }
      &:hover {
        border-color: $inst-color;
        background-color: $inst-color;
      }
    }
    &.vk {
      &:before {
        content: "\f189";
      }
      &:hover {
        border-color: $vk-color;
        background-color: $vk-color;
      }
    }
  }
}
