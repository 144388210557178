.full-screen {
  width: 100%;
  height: 100vh;
  background-color: $deepBlue;
  position: relative;
  overflow: hidden;
  @include r(870) {
    height: 700px;
  }
  @include r(510) {
    height: 560px;
  }
  & > .box {
    height: 100%;
    position: relative;
    z-index: 200;
  }
  .fs-image-wrap {
    width: 100%;
    height: 100%;
    background: {
      image: url("../img/line-pattern_white.png");
      repeat: repeat;
    };
    position: absolute;
    z-index: 120;
    opacity: 0.3 ;
    padding: 30px;
    .fs-image {
      width: calc(100% - 60px);
      height: calc(100% - 60px);
      background: {
        image: url("../img/cover-image_home-page.jpg");
        repeat: no-repeat;
        position: right bottom;
        size: cover;
        attachment: fixed;
      }
      position: absolute;
      z-index: 130;
      &:before {
        @extend %before;
        width: 100%;
        height: 100%;
        background-color: rgba(42,39,45,0.4);
      }
    }
  }
  //.fs-left-triangle {
  //  width: 100%;
  //  height: 100%;
  //  margin-left: -50%;
  //  background-color: $orange;
  //  position: absolute;
  //  left: 0;
  //  z-index: 130;
  //  overflow: hidden;
  //  @include transform(skew(44.99deg));
  //}
  .fs-center-image-wrap {
    width: 400px;
    height: 400px;
    @extend %cam;
    z-index: 132;
    @include h(980) {
     width: 320px;
      height: 320px;
    }
    @include r(870) {
      display: none;
    }
    & img {
      position: absolute;
      &.fs-center-image {
        z-index: 2;
      }
      &.fs-center-image-line-triangle {
        z-index: 1;
      }
    }
  }
  .fs-decor-overlay {
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    background: {
      image: url("../img/elements_home_page_2000_980.png");
      repeat: no-repeat;
      position: center center;
      size: cover;
    };
    position: absolute;
    z-index: 134;
  }
  .fs-main-title {
    width: 540px;
    font: {
      family: $fontAlt;
      size: 3.375em;
    };
    line-height: 1;
    color: #ffffff;
    position: absolute;
    @extend %vam;
    left: $leftOffset;
    @include r(1200) {
      left: $leftOffsetMiddle;
    }
    @include r(870) {
      font-size: 3.200em;
      left: $leftOffsetSmall;
    }
    @include r(870) {
      top: 260px;
      @include transform(translateY(0));
    }
    @include r(720) {
      width: auto;
      left: 30px;
      padding-right: 30px;
    }
    @include r(510) {
      top: 190px;
    }
    @include r(510) {
      font-size: 2.500em;
      line-height: 1.3;
    }
    &:before,
    &:after {
      @extend %before;
      width: 40px;
      height: 6px;
      background-color: #ffffff;
      left: 0;
    }
    &:before {
      top: -46px;
      @include r(510) {
        bottom: -36px;
      }
    }
    &:after {
      bottom: -46px;
      @include r(510) {
        bottom: -36px;
      }
    }
  }
  .fs-main-desc {
    font-size: 1.375em;
    line-height: 1.4;
    text-transform: uppercase;
    color: #ffffff;
    position: absolute;
    left: $leftOffset;
    bottom: 60px;
    @include r(1200) {
      bottom: 180px;
    }
    @include r(1200) {
      left: $leftOffsetMiddle;
    }
    @include r(870) {
      left: $leftOffsetSmall;
    }
    @include r(720) {
      left: 30px;
    }
    @include r(510) {
      bottom: 110px;
    }
    & .fs-main-desc-after {
      display: block;
      width: 1px;
      height: 100px;
      background-color: #ffffff;
      margin-top: 30px;
      @include h(980) {
        display: none;
      }
      @include r(1200) {
        display: none;
      }
    }
    & p {
      margin: 0;
      padding: 0;
    }
  }
  .social-links {
    position: absolute;
    left: 30px;
    bottom: 60px;
    @include r(510) {
      bottom: 30px;
    }
    a {
      border-color: #ffffff;
      color: #ffffff;
      &:hover {
        border-color: #ffffff;
        background-color: #ffffff;
        &:before {
          color: $black;
        }
      }
    }
  }
}