//home page menu
.phone {
  font: {
    family: $fontAltMd;
  };
  color: #ffffff;
  padding: 0 0 0 30px;
  position: relative;
  &:before {
    @include icomoon;
    content: "\e903";
    font-size: 22px;
    position: absolute;
    @extend %vam;
    left: 0;
  }
}

.logo-wrap {
  @extend %ibm;
  float: left;
  text-align: left;
  @include r(1300) {
    font-size: 0;
  }
  .logo-icon {
    display: block;
    width: 50px;
    height: 50px;
    border: solid 2px #ffffff;
    position: relative;
    @include r(1300) {
      display: inline-block;
      vertical-align: top;
      margin-right: 20px;
    }
    &:after {
      pointer-events: none;
      position: absolute;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      content: '';
      top: -2px;
      left: -2px;
      z-index: -1;
      border: solid 2px #ffffff;
      box-sizing: border-box;
      transition: transform $time, opacity $time;
    }
    &:hover {
      &:after {
        transform: scale(1.4);
        opacity: 0;
      }
    }
  }
  .logo-text {
    display: block;
    width: 138px;
    height: 50px;
    margin-top: 20px;
    transition: all $time;
    @include r(1300) {
      display: inline-block;
      margin-top: 0;
      vertical-align: top;
    }
    @include r(620) {
      display: none;
    }
  }
}

.home-page-menu {
  padding: 60px 30px 0 30px;
  text-align: right;
  @include r(510) {
    padding: 30px 30px 0 30px;
  }
}

.hp-menu-wrap {
  @extend %ibm;
  border: solid 1px #ffffff;
  @include r(1300) {
    display: none;
  }
  .hp-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0;
    li {
      @extend %ibm;
      margin: 0 30px;
      &.phone {
        @extend %ibm;
        font-size: 0.938rem;
        margin: 0 120px;
        @include r(1420) {
          margin: 0 60px 0 120px;
        }
        &:after {
          @extend %before;
          width: 60px;
          height: 1px;
          background-color: #ffffff;
          @extend %vam;
          left: -86px;
        }
      }
      a {
        font: {
          family: $fontAlt;
          size: 1.063rem;
        };
        line-height: 48px;
        color: #ffffff;
        position: relative;
        transition: all $time;
        &:before {
          @extend %before;
          width: 0;
          height: 1px;
          background-color: $orange;
          left: -4%;
          bottom: 8px;
          transition: all $time;
        }
        &:hover {
          color: $orange;
          &:before {
            width: 108%;
          }
        }
      }
    }
  }
}

.main-page-menu-wrap {
  padding: 40px 0;
  background-color: $orange;
  @include r(510) {
    padding: 30px;
  }
  .main-page-menu {
    padding: 0 30px;
    text-align: right;
    min-height: 50px;
    @include r(510) {
      padding: 0;
    }
    .logo-wrap {
      .logo-icon {
        @extend %ibm;
        width: 50px;
        height: 50px;
        margin-right: 20px;
        transition: all $time;
        &:after {
          z-index: 0;
        }
        @include r(510) {
          margin-right: 0;
        }
      }
      .logo-text {
        @extend %ibm;
        width: 226px;
        height: 50px;
        position: relative;
        left: 0;
        margin-top: 0;
        transition: all $time;
        @include r(510) {
          display: none;
        }
      }
    }
    .hp-menu-wrap {
      border: none;
      .hp-menu {
        li {
          @extend %ibm;
          margin: 0 10px;
          border: solid 1px transparent;
          transition: all $time;
          &:last-child {
            margin-right: 0;
          }
          a {
            font: {
              family: $fontAlt;
              size: 1.063rem;
            };
            padding: 0 15px;
            line-height: 48px;
            color: #ffffff;
            position: relative;
            transition: all $time;
            &:before {
              display: none;
            }
          }
          &:hover {
            border-color: #ffffff;
            a {
              color: #ffffff;
            }
          }
          &.active {
            border-color: rgba(255,255,255,0.8);
            a {
              color: rgba(255,255,255,0.8);
            }
          }
          &.phone {
            margin: 0 40px;
            @include r(1420) {
              margin: 0 30px 0 20px;
            }
            &:hover {
              border-color: transparent;
            }
          }
        }
      }
    }
  }
}

.burger-menu {
  display: none;
  margin-top: 20px;
  @include r(1300) {
    display: inline-block;
  }
}

.footer-menu-wrap {
  width: 100%;
  margin: 70px 0;
  padding: 0 0 0 200px;
  @include display-flex();
  @include flex-wrap(wrap);
  @include justify-content(left);
  @include r(1200) {
    padding: 0;
  }
  @include r(620) {
    margin: 40px 0;
  }
  ul {
    width: 25%;
    text-align: left;
    @include r(870) {
      width: 50%;
      margin-bottom: 70px;
    }
    @include r(620) {
      width: 100%;
      text-align: center;
      margin-bottom: 35px;
    }
    li {
      margin-bottom: 12px;
      &.menu-title {
        font: {
          family: $fontAltMd;
          size: 0.938em;
        };
        color: $black;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      a {
        color: $grey;
        line-height: 1.2;
        transition: color $time;
        &:hover {
          color: $orange;
        }
      }
    }
  }
}

.burger-menu {
  font: {
    family: $fontAltMd;
    size: 0.813em;
  };
  padding-right: 40px;
  text-transform: uppercase;
  color: #ffffff;
  position: relative;
  z-index: 320;
  transition: all $time;
  & .burger-menu-icon {
    width: 30px;
    height: 2px;
    background-color: #ffffff;
    position: absolute;
    @extend %vam;
    right: 0;
    transition: all $time;
    &:before,
    &:after {
      @extend %before;
      width: 20px;
      height: 2px;
      background-color: #ffffff;
      right: 0;
      transition: all $time;
    }
    &:before {
      top: -5px;
    }
    &:after {
      bottom: -5px;
    }
  }
  &:hover {
    padding-right: 35px;
    & .burger-menu-icon {
      width: 20px;
      &:before,
      &:after {
        width: 30px;
      }
    }
  }
  &.active {
    padding-right: 35px;
    .burger-menu-icon {
      width: 0;
      &:before {
        top: 50%;
        @include transform(rotate(45deg));
      }
      &:after {
        top: 50%;
        @include transform(rotate(-45deg));
      }
    }
    &:hover {
      .burger-menu-icon {
        &:before,
        &:after {
          width: 20px;
        }
      }
    }
  }
}

.scroll-menu {
  width: 100%;
  height: 70px;
  padding: 0 30px;
  background-color: $orange;
  position: fixed;
  top: -70px;
  left: 0;
  z-index: 300;
  @include display-flex();
  @include align-items(center);
  @include flex-wrap(wrap);
  @include justify-content(space-between);
  opacity: 1;
  transition: top $time, opacity $time;
  .phone {
    width: 40%;
    font-size: 0.875em;
    @include r(510) {
      display: none;
    }
  }
  .logo-wrap {
    width: 20%;
    .logo {
      display: block;
      width: 50px;
      height: 50px;
      margin: 0 auto;
      border: solid 2px #ffffff;
      position: relative;
      @include r(510) {
        margin: 0;
      }
      &:after {
        pointer-events: none;
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        content: '';
        top: -2px;
        left: -2px;
        z-index: -1;
        border: solid 2px #ffffff;
        box-sizing: border-box;
        transition: transform $time, opacity $time;
      }
      &:hover {
        &:after {
          @include transform(scale(1.4));
          opacity: 0;
        }
      }
    }
  }
  .burger-menu-wrap {
    width: 40%;
    text-align: right;
    & .burger-menu {
      display: inline-block;
      margin-top: 0;
    }
  }
  &.nav-up {
    top: -70px;
  }
  &.nav-down {
    top: 0;
  }
  &.hide-menu {
    top: -70px;
  }
}

.full-screen-menu-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: $orange;
  z-index: 600;
  transition: all $time;
  transform: scale(0);
  opacity: 0;
  .burger-menu {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 30px;
    top: 56px;
    transition: all $time;
    @include r(670) {
      top: 30px;
    }
    @include h(500) {
      top: 20px;
    }
    &:hover {
      transform: scale(1.2);
    }
  }
  &.show {
    transform: scale(1);
    opacity: 1;
  }
  .full-screen-menu-content {
    .logo-wrap {
      width: 100%;
      float: none;
      text-align: center;
      margin: 62px 0 20px 0;
      padding-bottom: 60px;
      border-bottom: solid 1px rgba(255,255,255, 0.3);
      @include r(670) {
        margin: 32px 0 0 0;
        padding-bottom: 30px;
      }
      @include h(500) {
        margin: 20px 0 0 0;
        padding-bottom: 22px;
      }
      .logo {
        display: block;
        width: 50px;
        height: 50px;
        margin: 0 auto;
        border: solid 2px #ffffff;
        position: relative;
      }
    }
    .hp-menu-wrap {
      display: block;
      text-align: center;
      border: none;
      li {
        margin: 30px 0;
        display: block;
        @include r(670) {
          margin: 20px 0;
        }
        @include h(500) {
          margin: 16px 0;
        }
        a {
          line-height: 1.3;
          text-transform: uppercase;
          font-size: 1.125rem;
          @include h(500) {
            font-size: 1rem;
          }
          &:before {
            bottom: 10px;
          }
          &:hover {
            color: #ffffff;
            &:before {
              background-color: #ffffff;
            }
          }
        }
      }
    }
  }
}
