.main-footer {
  width: 100%;
  background-color: $orange;
  padding: 0 30px 30px 30px;
  position: absolute;
  bottom: 0;
  z-index: 10;
  overflow: hidden;
  @include r(1000) {
    padding: 0;
  }
  .main-footer-inner {
    width: 100%;
    background-color: #ffffff;
    padding-top: 40px;
    & > .box {
      padding: 0 30px;
    }
  }
  .footer-contacts {
    width: 100%;
    @include display-flex();
    @include flex-wrap(wrap);
    @include align-items(center);
    @include justify-content(space-between);
    .footer-contacts-logo {
      height: 50px;
      font-size: 0;
      @include r(620) {
        margin: 0 auto;
      }
      .logo-icon {
        @extend %ibm;
        width: 50px;
        height: 50px;
        margin-right: 20px;
        transition: all $time;
        @include r(510) {
          margin-right: 0;
        }
      }
      .logo-text {
        @extend %ibm;
        width: 226px;
        height: 50px;
        position: relative;
        left: 0;
        transition: left $time;
        @include r(510) {
          display: none;
        }
      }
      &:hover {
        .logo-icon {
          @include transform(scale(1.05));
        }
        .logo-text {
          left: -5px;
        }
      }
    }
    .footer-contacts-location {
      font: {
        size: 1.125em;
      };
      text-transform: uppercase;
      color: $grey;
      @include r(1300) {
        font-size: 1em;
      }
      @include r(1200) {
        font-size: 1.125em;
      }
      @include r(870) {
        width: 100%;
        margin-top: 30px;
      }
      @include r(620) {
        text-align: center;
        line-height: 1.3;
      }
      & .label-item {
        font: {
          family: $fontAltMd;
          size: 0.833em;
        };
        color: $black;
        text-transform: none;
        @include r(620) {
          display: block;
          margin-bottom: 5px;
        }
      }
    }
    .footer-contacts-on-map {
      min-width: inherit;
      border: solid 1px $orange;
      color: $orange;
      font: {
        family: $fontAltMd;
        size: 0.813em;
      };
      padding: 0 75px 0 45px;
      position: relative;
      @include r(1300) {
        padding: 0 25px 0 45px;
      }
      @include r(1200) {
        display: none;
      }
      &:before {
        @include icomoon;
        content: "\e900";
        font-size: 22px;
        position: absolute;
        @extend %vam;
        left: 20px;
        transition: left $time;
      }
      &:after {
        @include icomoon;
        content: "\e905";
        font-size: 15px;
        position: absolute;
        @extend %vam;
        right: 20px;
        transition: left $time;
        @include r(1300) {
          display: none;
        }
      }
      &:hover {
        background-color: $orange;
        border-color: $orange;
        color: #ffffff;
        &:before {
          left: 14px;
        }
        &:after {
          right: 14px;
        }
      }
    }
  }
  .social-links {
    width: 100%;
    text-align: center;
    a {
      border-color: rgba($grey,0.3);
      &:before {
        color: $orange;
      }
      &:hover {
        border-color: $orange;
        background-color: $orange;
        &:before {
          color: #ffffff;
        }
      }
    }
  }
  .footer-copy-wrap {
    width: 100%;
    padding: 30px 0;
    margin-top: 30px;
    border-top: solid 1px rgba($grey,0.3);
    font: {
      family: $fontAlt;
      size: 0.813em;
    };
    color: $grey;
    line-height: 1.2;
    @include display-flex();
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    @include r(620) {
      text-align: center;
    }
    .footer-copy {
      width: 70%;
      @include r(620) {
        width: 100%;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          @extend %ibm;
          position: relative;
          padding-right: 20px;
          @include r(620) {
            display: block;
            margin-bottom: 5px;
            padding: 0;
          }
          &:after {
            @include fontIconAw;
            content: '\f111';
            font-size: 4px;
            color: $grey;
            position: absolute;
            right: 7px;
            @extend %vam;
            @include r(620) {
              display: none;
            }
          }
          &:last-child {
            padding-right: 0;
            &:after {
              display: none;
            }
          }
          i {
            font-size: 10px;
          }
          strong {
            font: {
              family: $fontAltB;
              size: 0.923em;
            };
            color: $black;
          }
          a {
            color: $grey;
            transition: left $time;
            &:hover {
              color: $orange;
            }
          }
        }
      }
    }
    .footer-copy-author {
      width: 30%;
      text-align: right;
      @include r(620) {
        width: 100%;
        text-align: center;
        margin-top: 15px;
      }
      a {
        color: $black;
        font: {
          family: $fontAltB;
          size: 0.923em;
        };
        text-transform: uppercase;
        transition: left $time;
        &:hover {
          color: $orange;
        }
      }
    }
  }
}