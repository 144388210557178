.follow-us-wrap {
  width: 100%;
  background-color: #ffffff;
  padding: 30px;
  @include r(1000) {
    padding: 0;
  }
  .follow-us-inner {
    width: 100%;
    min-height: 540px;
    background-color: $orange;
    position: relative;
    overflow: hidden;
    @include r(870) {
      min-height: 440px;
    }
    @include r(620) {
      min-height: 100%;
    }
    .follow-us-decor-overlay {
      width: 100%;
      height: 100%;
      background: {
        image: url("../img/elements_social_block_1470_474.png");
        repeat: no-repeat;
        position: 50px 30px;
      };
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }
    .content-wrap {
      @extend %ibm;
      max-width: 300px;
      margin: 130px 0 0 calc(#{$leftOffset} - 30px);
      position: relative;
      z-index: 20;
      @include r(1200) {
        margin: 130px 0 0 calc(#{$leftOffsetMiddle} - 30px);
      }
      @include r(1000) {
        margin: 130px 0 0 $leftOffsetSmall;
      }
      @include r(870) {
        margin-top: 90px;
        padding-right: 30px;
      }
      @include r(720) {
        margin-left: 0;
        padding-left: 30px;
      }
      @include r(620) {
        max-width: 100%;
        margin: 0;
        padding: 60px 30px;
      }
      .decor-title {
        color: #ffffff;
        margin-bottom: 30px;
        &:before {
          background-color: #ffffff;
        }
      }
      .content-item {
        color: #ffffff;
        line-height: 1.2;
      }
      .social-links {
        margin-top: 50px;
        a {
          border-color: #ffffff;
          color: #ffffff;
          &:hover {
            border-color: #ffffff;
            background-color: #ffffff;
            &:before {
              color: $orange;
            }
          }
        }
      }
    }
    .follow-us-phone-wrap {
      width: 328px;
      height: 480px;
      background: {
        image: url("../img/iPhone.svg");
        position: center center;
        repeat: no-repeat;
      };
      position: absolute;
      right: 210px;
      bottom: 0;
      @include r(1300) {
        right: 180px;
      }
      @include r(1200) {
        right: 120px;
      }
      @include r(1000) {
        right: 60px;
      }
      @include r(870) {
        width: 260px;
        height: 380px;
        right: 30px;
      }
      @include r(620) {
        display: none;
      }
      .follow-us-phone-banner {
        width: 282px;
        height: 400px;
        background: {
          position: center center;
          repeat: no-repeat;
          size: cover;
        };
        position: absolute;
        bottom: 0;
        right: 19px;
        @include r(870) {
          width: 222px;
          height: 317px;
          right: 16px;
        }
        .logo-text {
          width: 138px;
          height: 50px;
          position: absolute;
          bottom: 30px;
          left: 30px;
        }
      }
    }
  }
}