.home-products-list-wrap {
  width: 100%;
  min-height: 600px;
  background-color: #ffffff;
  padding: 30px 30px 30px 120px;
  @include display-flex();
  @include flex-wrap(wrap);
  @include justify-content(left);
  @include r(1200) {
    padding: 30px 30px 30px 60px;
  }
  @include r(1000) {
    padding: 30px;
  }
  .hp-prod-left {
    width: 50%;
    position: relative;
    @include r(620) {
      width: 100%;
    }
    .main-title {
      margin: 46px 0 90px 0;
      @include r(870) {
        width: 100%;
        margin: 30px 0;
      }
      @include r(620) {
        text-align: center;
        margin: 16px 0 30px 0;
      }
    }
    .tab-content {
      @include r(870) {
        display: none;
        visibility: hidden;
      }
    }
    .product-desc-wrap {
      padding-right: 80px;
      @extend %ibm;
      position: absolute;
      visibility: hidden;
      &.fade {
        opacity: 0;
        transition: all $time;
        &.in {
          visibility: visible;
          opacity: 1;
        }
      }
      .product-desc-title {
        font: {
          size: 1.563em;
        };
        line-height: 1.3;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
      .product-desc {
        line-height: 1.3;
        margin-bottom: 47px;
      }
    }
  }
  .hp-prod-right {
    width: 50%;
    @include display-flex();
    @include flex-wrap(wrap);
    @include justify-content(left);
    @include r(870) {
      width: 100%;
    }
  }
}

.prod-item-select {
  cursor: pointer;
  width: 50%;
  height: 180px;
  overflow: hidden;
  position: relative;
  transition: all $time;
  @include r(620) {
    width: 100%;
  }
  .prod-item-select-link {
    display: none;
    @include r(870) {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 200;
    }
  }
  .prod-item-inner {
    width: 100%;
    padding: 0 20px;
    position: absolute;
    @extend %vam;
    text-align: center;
    .prod-item-icon {
      width: 50px;
      height: 50px;
      margin: 0 auto;
      position: relative;
      top: 0;
      transition: top $time;
      @include svg-elem {
        fill: $orange;
      }
    }
    .prod-item-name {
      width: 100%;
      font-size: 1.125em;
      line-height: 1.3;
      margin-top: 15px;
      text-transform: uppercase;
      position: relative;
      bottom: -15px;
      visibility: hidden;
      transition: opacity $time, color $time, bottom $time;
      opacity: 0;
      @include r(870) {
        color: $orange;
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &:hover {
    @include r(870) {
      box-shadow: inset 0px 0px 0px 2px $orange;
    }
    .prod-item-icon {
      top: 5px;
    }
    .prod-item-name {
      color: $orange;
      bottom: 0;
      opacity: 1;
      visibility: visible;
      @include r(870) {
        color: $black;
      }
    }
  }
  &.active {
    box-shadow: inset 0px 0px 0px 2px $orange;
    .prod-item-icon {
      top: 0;
    }
    .prod-item-name {
      color: $black;
      bottom: 0;
      opacity: 1;
      visibility: visible;
      @include r(870) {
        color: $orange;
        bottom: -15px;
      }
    }
    @include r(870) {
      box-shadow: none;
      &:hover {
        box-shadow: inset 0px 0px 0px 2px $orange;
        .prod-item-icon {
          top: 5px;
        }
        .prod-item-name {
          color: $black;
          bottom: 0;
        }
      }
    }
  }
}

.products-list {
  background-color: #ffffff;
  padding: 0 30px 30px 30px;
  .products-list-inner {
    border-style: solid;
    border-color: rgba($lightGrey, 0.6);
    border-width: 1px 0 0 1px;
    @include display-flex();
    @include flex-wrap(wrap);
    @include align-items(center);
    @include justify-content(space-between);
    .prod-item-select {
      position: relative;
      border-style: solid;
      border-color: rgba($lightGrey, 0.6);
      border-width: 0 1px 1px 0;
      & .prod-item-select-link {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 80;
      }
      .prod-item-icon {
        top: 0;
      }
      .prod-item-name {
        color: $black;
        bottom: 0;
        opacity: 1;
        visibility: visible;
      }
      &:hover {
        box-shadow: inset 0 0 0 2px $orange;
        @include r(870) {
          box-shadow: inset 0 0 0 2px $orange;
        }
        .prod-item-name {
          color: $orange;
        }
      }
    }
  }
}

.products-single-layout {
  ul.projects-page-filters {
    margin: 30px 0 0 0;
    float: right;
  }
}

.products-list-examples-wrap {

}

//individual order
.individual-order-wrap {
  width: 100%;
  min-height: 600px;
  background: {
    image: url("../img/individual-order-cover-image_01.jpg");
    repeat: repeat-y;
    position: center center;
    size: 100%;
    attachment: fixed;
  };
  position: relative;
  overflow: hidden;
  z-index: 10;
  @include r(1300) {
    background: {
      size: auto;
    }
  }
  @include r(870) {
    min-height: 100%;
    background: {
      position: right center;
    }
  }
  &:before {
    @extend %before;
    width: 100%;
    height: 100%;
    background-color: rgba($black,0.5);
    left: 0;
    top: 0;
  }
  .in-order-overlay {
    width: 100%;
    height: 100%;
    background: {
      image: url("../img/elements_home_page_2000_980.png");
      repeat: no-repeat;
      position: center bottom;
    };
    position: absolute;
    left: 0;
    top: 0;
    z-index: 20;
  }
  & > .box {
    position: relative;
    z-index: 30;
  }
  .individual-order-content {
    width: 100%;
    padding: 110px 0 0 $leftOffset;
    @include r(1200) {
      padding: 110px 0 0 $leftOffsetMiddle;
    }
    @include r(1000) {
      padding: 110px 0 0 $leftOffsetSmall;
    }
    @include r(870) {
      padding: 90px 0 90px $leftOffsetSmall;
    }
    @include r(720) {
      margin-left: 0;
      padding-left: 30px;
    }
    @include r(620) {
      margin: 0;
      padding: 60px 30px;
    }
  }
  .content-wrap {
    max-width: 680px;
    color: #ffffff;
    @include r(1000) {
      max-width: 100%;
      padding-right: 0;
    }
    .decor-title {
      margin-bottom: 27px;
      &:before {
        background-color: #ffffff;
      }
    }
    .content-item {
      font: {
        size: 1.563em;
      };
      line-height: 1.2;
      margin-bottom: 27px;
      @include r(620) {
        font: {
          size: 1.250em;
        };
      }
    }
    .content-item-sub {
      line-height: 1.2;
      @include r(870) {
        display: none;
      }
    }
    .cta-btn {
      margin-top: 40px;
      border-color: #ffffff;
      color: #ffffff;
      &:hover {
        background-color: #ffffff;
        color: $black;
      }
    }
  }
}