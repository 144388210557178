.projects-module {
  padding: 0 80px 60px 120px;
  background-color: #ffffff;
  overflow: hidden;
  @include r(1200) {
    padding: 0 40px 60px 60px;
  }
  @include r(1000) {
    padding: 0 10px 60px 30px;
  }
  @include r(620) {
    padding: 60px 30px;
  }
  @include r(510) {
    padding: 30px 30px 60px 30px;
  }
  .prj-list-bottom {
    width: calc(100% - 40px);
    text-align: center;
    @include r(1000) {
      width: calc(100% - 20px);
    }
    @include r(620) {
      width: 100%;
    }
  }
}

.projects-page-layout {
  .projects-module {
    @include r(620) {
      padding: 30px;
      .prj-list-wrap {
        margin-top: 0;
      }
    }
    @include r(510) {
      padding: 30px;
    }
  }
}

.projects-desc-wrap {
  width: 100%;
  background-color: $mint;
  padding: 30px;
  overflow: hidden;
  @include r(1000) {
    padding: 0;
  }
  .projects-desc-wrap-inner {
    padding: 60px 90px;
    background-color: #ffffff;
    @include r(1200) {
      padding: 60px 30px;
    }
    @include r(770) {
      padding: 30px;
    }
  }
  .projects-desc-part {
    margin-bottom: 60px;
    font-size: 0;
    background: {
      image: url("../img/elements_material_slider_330_270.png");
      repeat: no-repeat;
      position: left bottom;
    };
    &.no-bg {
      background: none;
    }
    @include r(770) {
      background: none;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    .projects-desc-part-title {
      @extend %ibt;
      width: 300px;
      @include r(770) {
        width: 100%;
        margin-bottom: 20px;
      }
      h3 {
        font-size: 1.563rem;
        color: $orange;
        text-transform: uppercase;
        line-height: 1.3;
        @include r(770) {
          font-size: 1.250rem;
        }
      }
    }
    .projects-desc-part-content {
      @extend %ibt;
      width: calc(100% - 300px);
      font-size: 1rem;
      padding-left: 90px;
      @include r(1200) {
        padding-left: 60px;
      }
      @include r(770) {
        width: 100%;
        padding-left: 0;
      }
      .cta-text {
        font: {
          family: $fontMd;
          size: 1.375rem;
        };
        margin-top: 40px;
        line-height: 1.4;
      }
      .cta-link {
        text-transform: uppercase;
        color: $orange;
        line-height: 1.4;
        position: relative;
        &:before {
          @extend %before;
          width: 100%;
          height: 1px;
          border-bottom: dotted 1px $orange;
          left: 0;
          bottom: 0;
          transition: all $time;
        }
        &:hover {
          &:before {
            width: 0;
          }
        }
      }
      p {
        margin-bottom: 20px;
        line-height: 1.3;
        &:last-child {
          margin-bottom: 0;
        }
      }
      ul {
        margin: 0 0 20px 0;
        padding: 0 0 0 15px;
        list-style: none;
        li {
          margin-bottom: 15px;
          padding: 0 0 0 15px;
          line-height: 1.4;
          position: relative;
          &:before {
            @extend %before;
            width: 5px;
            height: 5px;
            background-color: $orange;
            left: 0;
            top: 10px;
            border-radius: 50%;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.prj-title-wrap {
  width: 100%;
  padding-right: 40px;
  @include display-flex();
  @include flex-wrap(wrap);
  @include align-items(center);
  @include justify-content(space-between);
  overflow: hidden;
  @include r(770) {
    padding-right: 20px;
    position: relative;
    overflow: visible;
  }
  @include r(510) {
    padding-right: 0;
  }
  .main-title {
    width: 20%;
    margin-bottom: 0;
    @include r(870) {
      width: 100%;
      margin-bottom: 15px;
    }
    @include r(770) {
      width: auto;
      margin-bottom: 0;
    }
  }
  .prj-filters-wrap {
    width: 80%;
    text-align: right;
    .burger-menu {
      display: none;
      @include r(770) {
        display: inline-block;
        height: 20px;
        text-align: left;
        .burger-menu-icon {
          background-color: $orange;
          &:before,
          &:after {
            background-color: $orange;
          }
        }
        &.active {
          .burger-menu-icon {
            width: 0;
            &:before {
              top: 50%;
              @include transform(rotate(45deg));
            }
            &:after {
              top: 50%;
              @include transform(rotate(-45deg));
            }
          }
          &:hover {
            .burger-menu-icon {
              &:before,
              &:after {
                width: 20px;
              }
            }
          }
        }
      }
    }
    @include r(870) {
      width: 100%;
      text-align: left;
    }
    @include r(770) {
      width: 40px;
      text-align: right;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 0;
      @include r(770) {
        display: none;
        width: auto;
        height: auto;
        padding: 0 30px;
        background-color: #ffffff;
        border: solid 1px $orange;
        position: absolute;
        top: 100%;
        right: 20px;
        z-index: 300;
      }
      @include r(510) {
        right: 0;
      }
      li {
        @extend %ibm;
        font: {
          family: $fontAlt;
          size: 1rem;
        };
        margin: 0 20px;
        text-transform: uppercase;
        color: $black;
        cursor: pointer;
        position: relative;
        transition: all $time;
        @include r(770) {
          width: auto;
          display: block;
          text-align: center;
          margin: 20px 0;
        }
        &:before {
          @extend %before;
          width: 0;
          height: 1px;
          background-color: rgba($orange,0.2);
          left: -4%;
          bottom: 7px;
          transition: all $time;
          @include r(770) {
            display: none;
          }
        }
        &:hover,
        &.is-checked {
          color: $orange;
          &:before {
            width: 108%;
          }
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.prj-list-wrap {
  width: 100%;
  margin-top: 30px;
  &:after {
    content: '';
    display: block;
    clear: both;
  }
  .prj-item {
    float: left;
    width: calc(33.3% - 40px);
    height: 540px;
    margin: 0 40px 40px 0;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    .prj-item-link {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 100;
    }
    @include r(1200) {
      margin: 0 20px 20px 0;
      width: calc(33.3% - 20px);
    }
    @include r(1000) {
      width: calc(50% - 20px);
      height: 360px;
    }
    @include r(620) {
      width: 100%;
      margin: 0 0 20px 0;
    }
    &:before {
      @extend %before;
      width: 100%;
      height: 100%;
      background-color: rgba($black, 0.5);
      opacity: 0;
      z-index: 20;
      transition: all $time;
    }
    &:nth-child(2n)  {
      height: 360px;
    }
    .prj-item-cover-img {
      width: 100%;
      height: 100%;
      background: {
        repeat: no-repeat;
        position: center center;
        size: cover;
      };
      transition: all $time;
    }
    .prj-item-desc {
      width: 90%;
      font-size: 1.563em;
      color: #ffffff;
      line-height: 1.3;
      text-transform: uppercase;
      position: absolute;
      left: 30px;
      bottom: 50px;
      z-index: 30;
      visibility: hidden;
      opacity: 0;
      transition: opacity $time, bottom $time;
      p {
        margin: 0;
        padding: 0;
      }
      .prj-item-desc-details {
        font-size: 0.640em;
        text-transform: none;
        margin-top: 40px;
        color: rgba(255,255,255,0.8);
        span {
          display: block;
          text-transform: uppercase;
        }
      }
    }
    &:hover {
      &:before {
        opacity: 1;
      }
      .prj-item-cover-img {
        @include transform(scale(1.1));
      }
      .prj-item-desc {
        bottom: 30px;
        visibility: visible;
        opacity: 1;
      }
    }
    &.advertising-banner {
      height: 360px;
      background: {
        image: url("../img/elements_prj_banner_360_360.png");
        repeat: no-repeat;
        position: center center;
        size: cover;
      };
      @include r(620) {
        display: none;
      }
      &:before {
        display: none;
      }
      &:hover {
        &:before {
          opacity: 0;
        }
      }
    }
  }
}

.projects-page-filters-wrap {
  background-color: #ffffff;
  .projects-page-filters-inner {
    padding: 30px 120px 40px 120px;
    position: relative;
    @include r(1200) {
      padding: 30px 60px 40px 60px;
    }
    @include r(1000) {
      padding: 30px 30px 40px 30px;
    }
    @include r(620) {
      padding: 30px;
    }
    @include r(510) {
      padding: 30px;
    }
    .main-title {
      @extend %ibm;
      margin-bottom: 0;
    }
    .burger-menu {
      display: none;
      @include r(870) {
        float: right;
        display: inline-block;
        height: 20px;
        margin-top: 15px;
        text-align: left;
        .burger-menu-icon {
          background-color: $orange;
          &:before,
          &:after {
            background-color: $orange;
          }
        }
        &.active {
          .burger-menu-icon {
            width: 0;
            &:before {
              top: 50%;
              @include transform(rotate(45deg));
            }
            &:after {
              top: 50%;
              @include transform(rotate(-45deg));
            }
          }
          &:hover {
            .burger-menu-icon {
              &:before,
              &:after {
                width: 20px;
              }
            }
          }
        }
      }
    }
    .projects-page-filters {
      @extend %ibm;
      margin: 30px 0 0 140px;
      @include r(1200) {
        float: right;
        margin: 20px 0 0 0;
      }
      @include r(870) {
        float: none;
        display: none;
        text-align: center;
      }
      li {
        @extend %ibm;
        cursor: pointer;
        padding-bottom: 15px;
        position: relative;
        transition: all $time;
        @include r(870) {
          display: block;
          padding: 0;
          margin-bottom: 30px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        span {
          font-size: 1rem;
          padding: 0 40px;
          @include r(870) {
            display: block;
            padding: 0 10px;
          }
        }
        &.active {
          color: $orange;
        }
        &.has-sub-menu {
          span {
            position: relative;
            &:after {
              @include fontIconAw;
              content: "\f107";
              font-size: 12px;
              color: $lightGrey;
              margin-left: 10px;
              transition: all $time;
              @include r(870) {
                display: none;
              }
            }
          }
          @include r(870) {
            background-color: rgba($light, 0.5);
            span {
              padding-top: 20px;
              color: $lightGrey;
            }
          }
          .sub-menu {
            width: 100%;
            padding: 30px 20px 30px 30px;
            background-color: #ffffff;
            border-top: solid 2px $orange;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 200;
            visibility: hidden;
            opacity: 0;
            box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
            transition: all $time;
            @include r(870) {
              border-top: none;
              position: relative;
              top: 0;
              left: 0;
              padding: 10px 0 20px 0;
              visibility: visible;
              background-color: transparent;
              opacity: 1;
              box-shadow: none;
            }
            li {
              display: block;
              margin-bottom: 15px;
              padding: 0;
              &:last-child {
                margin-bottom: 0;
              }
              a {
                display: inline-block;
                font-size: 0.875rem;
                text-transform: uppercase;
                color: $black;
                position: relative;
                transition: all $time;
                &:before {
                  @extend %before;
                  width: 0;
                  height: 1px;
                  background-color: $orange;
                  position: absolute;
                  @extend %vam;
                  left: -20px;
                  transition: all $time;
                }
                &:hover {
                  color: $orange;
                  margin-left: 20px;
                  &:before {
                    width: 15px;
                  }
                }
              }
            }
          }
          &.open-sub-menu {
            span {
              &:after {
                content: "\f106";
              }
            }
            .sub-menu {
              visibility: visible;
              opacity: 1;
            }
          }
        }
        &:hover {
          color: $grey;
          span {
            &:after {
              color: $grey;
            }
          }
          &.active {
            color: $orange;
            span {
              &:after {
                color: $orange;
              }
            }
          }
        }
      }
    }
  }
}

//projects slider
.projects-slider-wrap {
  width: 100%;
  height: 540px;
  overflow: hidden;
  .slick-arrow {
    width: 55px;
    position: absolute;
    color: transparent;
    @extend %vam;
    right: 30px;
    z-index: 20;
    transition: all $time;
    &:before {
      @include icomoon;
      content: "\e906";
      font-size: 10px;
      color: #ffffff;
      transition: all $time;
    }
    &:hover {
      &:before {
        color: $orange;
      }
    }
    &.slick-prev {
      margin-top: -70px;
      @include transform(rotate(180deg));
      &:hover {
        right: 40px;
      }
    }
    &.slick-next {
      margin-top: 70px;
      &:hover {
        right: 20px;
      }
    }
  }
  .slick-dots {
    width: 100%;
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 20;
    li {
      @extend %ibm;
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
      & button {
        width: 12px;
        height: 12px;
        border: solid 2px #ffffff;
        text-indent: -9999px;
        position: relative;
        border-radius: 50%;
        transition: all $time;
        &:hover {
          border-color: #ffffff;
        }
      }
      &.slick-active {
        & button {
          border-color: $orange;
        }
      }
    }
  }
  .projects-slider-page-info {
    width: 55px;
    text-align: center;
    position: absolute;
    @extend %vam;
    color: #ffffff;
    right: 30px;
    z-index: 20;
    font: {
      family: $fontAltMd;
      size: 1.250em;
    }
    @include r(620) {
      top: 30px;
      right: auto;
      left: 30px;
      @include transform(translateY(0));
    }
    & .sup-element {
      font: {
        family: $fontAlt;
        size: 0.500em;
      }
      position: relative;
      margin-left: 5px;
      top: -5px;
    }
  }
  .projects-slide-item {
    width: 100%;
    height: 540px;
    background: {
      repeat: no-repeat;
      position: center center;
      size: cover;
    };
    position: relative;
    transition: all $time;
    &:before {
      @extend %before;
      width: 100%;
      height: 100%;
      background-color: rgba(42,39,45,0.55);
      transition: all $time;
    }
    &:after {
      @extend %before;
      width: 0;
      height: 4px;
      background-color: $orange;
      left: 0;
      bottom: 0;
      transition: all $time;
    }
    .projects-slide-item-link {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 30;
      transition: all $time;
    }
    .projects-slide-item-content {
      position: absolute;
      @extend %vam;
      padding-left: 360px;
      font: {
        family: $fontAlt;
        size: 0.875em;
      }
      z-index: 10;
      @include r(1200) {
        padding-left: 60px;
      }
      @include r(1000) {
        padding: 0 120px 0 30px;
      }
      &-title {
        font: {
          size: 2.200em;
        };
        line-height: 1.3;
        text-transform: uppercase;
        margin-bottom: 40px;
        color: #ffffff;
        transition: all $time;
      }
      &-sub {
        color: rgba(255,255,255,0.9);
        transition: all $time;
        p {
          margin-bottom: 12px;
          &:last-child {
            margin-bottom: 0;
          }
          & span {
            font: {
              size: 2.200em;
            };
          }
          sup {
            position: relative;
            top: -12px;
          }
        }
      }
    }
    &:hover {
      //@include transform(scale(1.05));
      &:before {
        background-color: rgba(42,39,45,0.75);
      }
      &:after {
        width: 100%;
      }
      .projects-slide-item-content {
        &-title {
          color: $orange;
          margin-bottom: 20px;
        }
      }
    }
  }
}


.layout-content-wrap {
  .layout-content-wrap-inner {
    background-color: #ffffff;
    padding: 30px 120px 40px 120px;
    position: relative;
    &.with-sidebar {
      @include display-flex();
      @include flex-wrap(wrap);
      @include align-items(top);
      @include justify-content(left);
      .with-sidebar-content-part {
        width: calc(100% - 320px);
        padding-right: 50px;
        @include r(870) {
          width: 100%;
          padding-right: 0;
        }
      }
      .sidebar-part {
        width: 320px;
        @include r(870) {
          width: 100%;
          margin-top: 50px;
        }
      }
    }
    @include r(1200) {
      padding: 30px 60px 40px 60px;
    }
    @include r(1000) {
      padding: 30px 30px 40px 30px;
    }
    @include r(620) {
      padding: 30px;
    }
    @include r(510) {
      padding: 30px;
    }
    .main-title-wrap {
      .main-title {
        @extend %ibm;
        margin-bottom: 0;
        @include r(870) {
          width: 100%;
        }
      }
      .cost-item {
        @extend %ibm;
        float: right;
        margin-top: 12px;
        font: {
          family: $fontAlt;
        };
        @include r(870) {
          width: 100%;
          float: none;
          margin-top: 25px;
        }
        & span {
          font: {
            size: 2em;
          };
        }
        sup {
          position: relative;
          top: -12px;
        }
      }
    }
    &.sp-detailed-info {
      overflow: hidden;
      .share-links {
        margin-top: 40px;
        .share-links-title {
          margin-bottom: 10px;
          text-transform: uppercase;
          line-height: 1.4;
        }
        a {
          @extend %ibm;
          min-width: 180px;
          margin-right: 6px;
          line-height: 40px;
          color: #ffffff;
          font: {
            family: $fontMd;
            size: 0.750em;
          };
          text-transform: uppercase;
          text-align: center;
          transition: all $time;
          &:last-child {
            margin-right: 0;
          }
          @include r(720) {
            margin: 0 6px 10px 0;
          }
          &.fb {
            background-color: $fb-color;
            &:hover {
              background-color: $orange;
            }
          }
          &.vk {
            background-color: $vk-color;
            &:hover {
              background-color: $orange;
            }
          }
          &.gpl {
            background-color: $gpl-color;
            &:hover {
              background-color: $orange;
            }
          }
        }
      }
    }
  }
  .project-single-content-desc {
    margin: 20px 0 60px 0;
    line-height: 1.4;
    @include r(510) {
      margin: 20px 0;
    }
    &-sub-title {
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    p {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .project-single-content-materials {
      color: $orange;
      margin-top: 20px;
      .project-single-content-mat-title {
        text-transform: uppercase;
        color: $black;
      }
      a {
        color: $orange;
        transition: all $time;
        &:hover {
          color: $grey;
        }
      }
    }
  }
  .sp-detailed-img-wrap {
    @include display-flex();
    @include flex-wrap(wrap);
    @include justify-content(left);
    .sp-detailed-img-part {
      width: calc(33.3% - 4px);
      height: 440px;
      margin-right: 4px;
      font-size: 0;
      @include r(1000) {
        width: calc(50% - 4px);
      }
      @include r(770) {
        width: 100%;
        margin-right: 0;
        height: 216px;
      }
      @include r(510) {
        width: 100%;
        height: auto;
        margin: 0;
      }
      & a {
        @extend %ibm;
        width: 100%;
        height: calc(50% - 4px);
        margin-bottom: 4px;
        background: {
          repeat: no-repeat;
          position: center center;
          size: cover;
        };
        position: relative;
        transition: all $time;
        &:before {
          @extend %before;
          width: 100%;
          height: 100%;
          background-color: rgba(255,255,255,0.2);
          position: absolute;
          opacity: 1;
          transition: all $time;
        }
        &:hover {
          &:before {
            opacity: 0;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        @include r(770) {
          width: calc(50% - 2px);
          height: 100%;
          margin-right: 4px;
          margin-bottom: 0;
          &:last-child {
            margin-right: 0;
          }
        }
        @include r(510) {
          width: 100%;
          height: 300px;
          margin: 0 0 4px 0;
        }
      }
      &.full-h-image {
        @include r(770) {
          margin-top: 4px;
        }
        a {
          height: calc(100% - 4px);
          margin-bottom: 0;
          @include r(770) {
            width: 100%;
            height: 100%;
          }
          @include r(510) {
            height: 300px;
          }
        }
      }
      &:last-of-type {
        margin-right: 0;
        @include r(1000) {
          width: 100%;
          height: 216px;
          a {
            width: calc(50% - 4px);
            height: 100%;
            margin-right: 4px;
            margin-bottom: 0;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        @include r(770) {
          margin-top: 4px;
          a {
            width: calc(50% - 2px);
          }
        }
        @include r(510) {
          height: auto;
          a {
            width: 100%;
            height: 300px;
            margin-bottom: 4px;
          }
        }
      }
    }
  }
  .project-single-content-banner {
    width: 100%;
    height: 540px;
    background: {
      repeat: no-repeat;
      position: center center;
      size: cover;
      attachment: fixed;
    };
    position: relative;
    transition: all $time;
    @include r(870) {
      background-attachment: inherit;
    }
    &:before {
      @extend %before;
      width: 100%;
      height: 100%;
      background-color: rgba(42,39,45,0.4);
      transition: all $time;
    }
    .project-single-content-banner-text {
      @extend %vam;
      font: {
        size: 2.200em;
      };
      padding-right: 30px;
      line-height: 1.4;
      text-transform: uppercase;
      color: #ffffff;
      margin-left: $leftOffset;
      position: absolute;
      z-index: 20;
      p {
        margin: 0;
        padding: 0;
      }
      @include r(1200) {
        margin-left: $leftOffsetMiddle;
      }
      @include r(1000) {
        max-width: 100%;
        margin-left: $leftOffsetSmall;
        padding-right: 30px;
      }
      @include r(720) {
        margin-left: 0;
        padding-left: 30px;
        font-size: 1.800em;
      }
      @include r(510) {
        font-size: 1.500em;
      }
    }
  }
  .project-single-content-partners {
    width: 100%;
    font-size: 0;
    margin-top: 10px;
    a {
      @extend %ibm;
      width: calc(25% - 12px);
      height: 140px;
      margin: 0 10px 10px 0;
      border: solid 1px rgba($lightGrey, 0.6);
      position: relative;
      transition: all $time;
      &:last-of-type {
        margin: 0 0 10px 0;
      }
      @include r(1000) {
        width: calc(33.3% - 12px);
      }
      @include r(770) {
        width: calc(50% - 12px);
      }
      @include r(510) {
        width: 100%;
        margin: 0 0 10px 0;
      }
      span {
        @extend %cam;
        font: {
          family: $fontAltB;
          size: 1rem;
        };
        text-transform: uppercase;
        color: $grey;
        white-space: nowrap;
        transition: all $time;
        & sup {
          font-size: 0.875rem;
          position: relative;
          top: -3px;
        }
      }
      &:hover {
        border-color: $orange;
        span {
          color: $orange;
        }
      }
    }
  }
}

.project-single-pagination {
  width: 100%;
  background-color: $mint;
  padding: 30px;
  overflow: hidden;
  @include r(1000) {
    padding: 0;
  }
  .project-single-pagination-inner {
    width: 100%;
    background-color: #ffffff;
    padding: 60px;
    font-size: 0;
    @include r(1000) {
      background-color: #ffffff;
      min-height: 100%;
    }
    @include r(720) {
      width: 100%;
      padding: 30px 30px 60px 30px;
    }
    .project-single-pagination-item {
      @extend %ibm;
      width: 50%;
      .pagination-btn {
        font-size: 1.563rem;
        line-height: 1.3;
        position: relative;
        white-space: normal;
        text-align: left;
        transition: all $time;
        @include r(1200) {
          font-size: 1.250rem;
        }
        @include r(770) {
          span {
            display: none;
          }
        }
        &:before {
          @extend %before;
          @include icomoon;
          content: "\e906";
          color: $orange;
          font-size: 14px;
          @extend %vam;
        }
      }
      &.project-single-pagination-prev {
        padding-right: 20px;
        .pagination-btn {
          padding-left: 100px;
          @include r(770) {
            padding: 0;
          }
          &:before {
            margin-top: -6px;
            left: 10px;
            @include transform(rotate(180deg));
            transition: all $time;
          }
          &:hover {
            color: $orange;
            &:before {
              left: 0;
            }
          }
        }
      }
      &.project-single-pagination-next {
        text-align: right;
        padding-left: 20px;
        .pagination-btn {
          text-align: right;
          padding-right: 100px;
          @include r(770) {
            padding: 0;
          }
          &:before {
            margin-top: 2px;
            right: 10px;
            transition: all $time;
          }
          &:hover {
            color: $orange;
            &:before {
              right: 0;
            }
          }
        }
      }
    }
  }
}

//for mobile devices
body.mobile {
  .prj-list-wrap .prj-item {
    &:before {
      opacity: 1;
    }
    .prj-item-desc {
      bottom: 30px;
      visibility: visible;
      opacity: 1;
    }
  }
}