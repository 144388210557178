/*main styles*/
html,
body {
  height: 100%;
}

body {
  font: {
    family: $font;
    size: 100%;
    weight: 300;
  }
  margin: 0;
  padding: 0;
  background-color: $light;
  color: $black;
  min-width: 320px;
  min-height: 100%;
  height: auto !important;
  position: relative;
  @include r(1200) {
    font-size: 0.875em;
  }
  @include r(510) {
    font-size: 0.813em;
  }
}

.main-container {
  //background-color: $light;
  //position: relative;
  //z-index: 1;
}

.box {
  width: 1400px;
  margin: 0 auto;
  @include r(1420) {
    width: 100%;
  }
}

.main-title {
  font: {
    family: $fontAlt;
    size: 2.500em;
  };
  color: $black;
  line-height: 1.3;
  margin-bottom: 30px;
}

.decor-title {
  font: {
    size: 1.563em;
  };
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 40px;
  position: relative;
  &:before {
    @extend %before;
    width: 80px;
    height: 1px;
    left: -120px;
    @extend %vam;
    @include r(1000) {
      width: 50px;
      left: -80px;
    }
    @include r(720) {
      display: none;
    }
  }
}

//breadcrumbs
.breadcrumbs-wrap {
  background-color: #ffffff;
  padding: 30px;
  @include r(1200) {
    font-size: 0.938rem;
  }
  a {
    @extend %ibm;
    color: $orange;
    position: relative;
    transition: all $time;
    &:before {
      @extend %before;
      width: 100%;
      height: 1px;
      background-color: $orange;
      left: 0;
      bottom: -1px;
      transition: all $time;
    }
    &:hover {
      &:before {
        width: 0;
      }
    }
  }
  span {
    @extend %ibm;
  }
  & .breadcrumbs-separator {
    @extend %ibm;
    width: 60px;
    margin: 0 5px;
    text-align: center;
    position: relative;
    top: -1px;
    &:after {
      @include icomoon;
      content: "\e906";
      color: $lightGrey;
      font-size: 10px;
      transition: right $time;
    }
  }
}

.project-single-layout,
.products-single-layout,
.material-single-layout,
.brand-material-single-layout {
  @include r(620) {
    .breadcrumbs-wrap {
      display: none;
    }
  }
}
